// External dependencies
import { Div } from 'atomize';
import React from 'react';

// Local dependencies
import iphoneInfo1 from '../../../assets/images/iphone-info-1.png';
import iphoneInfo2 from '../../../assets/images/iphone-info-2.png';
import Footer from '../../../components/website/footer/Footer';
import '../styles/styles.scss';
import MeducationHeader from './meducation-header';
import MeducationHero from './meducation-hero';
import MeducationADVideo from './meducation-video';
import MeducationBlock from './meducation-block';
import MeducationGuests from './meducation-guests';
import MeducationReviews from './meducation-reviews';
import MeducationCompanies from './meducation-companies';

export default function Meducation() {
  return (
    <Div className="meducation">
      <Div overflow="hidden">
        <MeducationHeader />
        <MeducationHero
          isDownloadQrCode={true}
          imgStyle={{
            pos: { md: 'absolute' },
            left: { md: '5%' },
            top: { md: '-5%' },
            w: { md: 'auto' },
            h: { md: '125%' },
            maxH: { sm: '400px', md: '125%' },
            m: { b: { xs: '-20%', sm: '-15%', md: '0' } },
            d: 'flex',
            justify: { xs: 'center', md: 'flex-start' },
          }}
        />
        <MeducationBlock
          blockBg="linear-gradient(135deg, #00D68F 0%, #98F0D3 100%);"
          blockPadding={{
            t: { xs: '98px', md: '84px' },
            b: { xs: '70px', md: '84px' },
          }}
          blockTitle="weCreatedSpecialAppForPharmacist"
          blockTitleColor="#fff"
          blockTitleTextAlign="center"
        />
      </Div>
      {/* <MeducationInfo /> */}
      <MeducationADVideo />
      <MeducationGuests />
      <MeducationReviews />
      <Div overflow="hidden">
        <MeducationCompanies linkToPartners="/meducation/partners" />
        <MeducationHero
          isDownloadQrCode={false}
          paddingY={{ xs: '3rem', md: '15rem' }}
          imgStyle={{
            d: { xs: 'none', md: ' block' },
            pos: { md: 'absolute' },
            left: { md: '5%' },
            top: { md: '50%' },
            transform: { md: 'translateY(-45%)' },
            w: { md: 'auto' },
            h: { md: '180%' },
          }}
        />
        <Footer />
      </Div>
    </Div>
  );
}
