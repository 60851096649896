/* External dependencies */
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import Layout from '@shared/ui/layout/WebLayout';
import SEO from '@shared/ui/layout/seo';
import Meducation from '@entities/meducation/ui/meducation';

export default function MeducationPage({ data }) {
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;
  const { t } = useI18next();

  return (
    <Layout>
      <SEO title={t('')} description={t('')} />
      <Meducation />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
