// External dependencies
import { Container, Div } from 'atomize';
import React from 'react';

// Local dependencies
import guests from '../../../../assets/images/guests.png';
import '../../styles/styles.scss';
import MeducationBlock from '../meducation-block';

export default function MeducationGuests() {
  return (
    <Div className="meducation-guests">
      <MeducationBlock
        blockBg="btn_default_bg"
        blockPadding={{
          t: { xs: '24px', md: '66px' },
          b: { xs: '24px', md: '40px' },
        }}
        blockTitle="weAlsoHoldEventsForOurPharmacists"
        blockTitleColor="bg_black"
        blockTitleTextAlign="left"
      >
        <Container>
          <Div
            maxW="100%"
            m={{ t: { xs: '24px', md: '46px' } }}
            className="meducation-guests__video"
          >
            <iframe
              width="1190"
              height="669"
              src="https://www.youtube.com/embed/NmD69KX1zKM"
              title="Самый умный фармацевт 2023"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Div>
        </Container>
      </MeducationBlock>
    </Div>
  );
}
