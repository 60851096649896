// External dependencies
import { Div } from 'atomize';
import React from 'react';
import { Trans } from 'react-i18next';

// Local dependencies
import meducationVideo from '../../../../assets/video/meducation-medcheck.mp4';

export default function MeducationADVideo() {
  return (
    <Div className="meducation-ad-video__container">
      <video autoPlay={true} playsInline muted>
        <source src={meducationVideo} type="video/mp4" />
        <Trans>browserDoesNotSupport</Trans>
      </video>
    </Div>
  );
}
